import React, { useState } from "react";
import { Box, Button, Typography, TextField, Modal } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { handleContactForm } from "../../store/actions/stationActions";
import { useDispatch } from "react-redux";
import "./stationDetails.scss";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  recipientName: Yup.string().required("Recipient Name is required"),
  recipientEmail: Yup.string()
    .email("Invalid email address")
    .required("Recipient Email is required"),
  stationInfo: Yup.string().required("Station Info is required"),
});

export default function ContactModal({
  openContactModal,
  handleCloseContactModal,
  station,
  IsEmail,
}) {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  let stationInfo = "";
  if (IsEmail) {
    stationInfo =
      "Sincerely,\n\nClean Energy Station Locator\nhttp://www.cnglngstations.com";
  } else {
    stationInfo = `Station Info \n\nStation: ${station?.StationName}\nAddress: ${station?.StationAddress} ${station?.StationCity}, ${station?.StationState} ${station?.StationZip}\nOpen Hours: ${station?.HoursOpen}\nAccepts: ${station?.PaymentTypesAccepted}`;
  }

  const handleSubmitContactForm = (values) => {
    const payload = {
      EmailAddress: values?.email,
      EmailBody: values?.stationInfo,
      EmailName: values?.name,
      RecipientEmail: values?.recipientEmail,
      RecipientName: values?.recipientName,
    };
    dispatch(handleContactForm({ payload }))
      .then(async (response) => {
        if (response?.status === 201 || response?.status === 200) {
          setSuccess("Contact Form SucessFully!");
          handleCloseContactModal();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("error.message", error.message);
        setError(error.message);
      });
  };

  return (
    <Modal
      className="contact-modal"
      open={openContactModal}
      onClose={handleCloseContactModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="contact-modal-body">
        <Formik
          initialValues={{
            name: "",
            email: "",
            recipientName: "",
            recipientEmail: "",
            stationInfo: stationInfo,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("Form values:", values);
            handleSubmitContactForm(values);
          }}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <Typography variant="h3">Contact Form</Typography>
              <Field
                name="name"
                as={TextField}
                label="Name"
                className="contact-input"
                variant="outlined"
                onChange={handleChange}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#000",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#08652e !important",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#08652e !important",
                  },
                }}
              />
              <Field
                name="email"
                as={TextField}
                label="Email"
                className="contact-input"
                variant="outlined"
                onChange={handleChange}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#000",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#08652e !important",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#08652e !important",
                  },
                }}
              />
              <Field
                name="recipientName"
                as={TextField}
                label="Recipient Name"
                className="contact-input"
                variant="outlined"
                onChange={handleChange}
                value={values.recipientName}
                error={touched.recipientName && Boolean(errors.recipientName)}
                helperText={touched.recipientName && errors.recipientName}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#000",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#08652e !important",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#08652e !important",
                  },
                }}
              />
              <Field
                name="recipientEmail"
                as={TextField}
                label="Recipient Email"
                className="contact-input"
                variant="outlined"
                onChange={handleChange}
                value={values.recipientEmail}
                error={touched.recipientEmail && Boolean(errors.recipientEmail)}
                helperText={touched.recipientEmail && errors.recipientEmail}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#000",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#08652e !important",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#08652e !important",
                  },
                }}
              />
              <Field
                name="stationInfo"
                as={TextField}
                multiline
                rows={4}
                className="contact-input"
                value={values.stationInfo}
                error={touched.stationInfo && Boolean(errors.stationInfo)}
                helperText={touched.stationInfo && errors.stationInfo}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "#000",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#08652e !important",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#08652e !important",
                  },
                }}
              />
              {success && <Typography variant="h3">{success}</Typography>}
              {error && <Typography variant="h3">{error}</Typography>}
              <Box className="contact-footer">
                {IsEmail && (
                  <Button variant="text" className="direction-btn">
                    Show Turn-by-turn Directions
                  </Button>
                )}
                <Box className="contact-footer-btn">
                  <Button
                    variant="contained"
                    onClick={handleCloseContactModal}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button variant="outlined" type="submit">
                    Send
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
