import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import { useMap } from 'react-leaflet';

L.Marker.prototype.options.icon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
});

const RoutingOnly = ({ sourceCity, destinationCity }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    if (sourceCity?.lat !== undefined && destinationCity?.lat !== undefined) {
      if (map.locate) {
        map.stopLocate(); // Stop locating if it's causing issues
      }
      const routingControl = L.Routing.control({
        waypoints: [
          L.latLng(parseFloat(sourceCity.lat), parseFloat(sourceCity.lng)),
          L.latLng(
            parseFloat(destinationCity.lat),
            parseFloat(destinationCity.lng)
          ),
        ],
        routeWhileDragging: false,
        lineOptions: {
          styles: [{ color: '#6FA1EC', weight: 4 }],
        },
        show: false,
        showAlternatives: true,
        draggableWaypoints: false,
        addWaypoints: false,
        fitSelectedRoutes: true,
      }).addTo(map);

      return () => {
        if (map && routingControl) {
          map.removeControl(routingControl);
        }
      };
    }
  }, [map, sourceCity, destinationCity]);

  return null;
};

export default RoutingOnly;
