import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "./PrintableRoutes.scss";
import { Box, Button, Typography } from "@mui/material";

import Logo from "../../assets/img//logo_stati_loc.png";
import RoutingOnly from "../TripPlanner/onlyRouting";
const PrintableRoutes = ({ routeDetails, destinatioinName, sourceName }) => {
  const position = routeDetails?.length > 0 && [
    routeDetails[0]?.startLocation?.lat,
    routeDetails[0]?.startLocation?.lng,
  ];

  const handlePrint = () => {
    window.print();
  };

  if (!routeDetails || routeDetails.length === 0) {
    return <p>No route details available.</p>;
  }

  return (
    <Box className="backsheet">
      <Box className="frontsheet">
        <Box className="print-container">
          <Box
            className="header-logo"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="" />
            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {new Date().toDateString()}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              <Typography
                sx={{
                  fontSize: 22,
                  textAlign: "left",
                  fontWeight: 700,
                  maxWidth: 750,
                }}
              >
                Route : {`"${sourceName}" To "${destinatioinName}"`}
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handlePrint}
                >
                  Print Route
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: 3,
                marginTop: 3,
              }}
            >
              {routeDetails.map((route) => (
                <Box key={route.index}>
                  <Typography
                    className="estimation"
                    sx={{ display: "inline-block" }}
                  >
                    <span>Estimated distance:</span> {route.distance}
                  </Typography>
                  <Typography
                    className="estimation"
                    sx={{ display: "inline-block", marginLeft: 10 }}
                  >
                    <span>Estimated time:</span> {route.duration}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <MapContainer
                center={position}
                zoom={6}
                style={{ height: "500px", width: "100%", position: "relative" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <RoutingOnly
                  sourceCity={{
                    lat: routeDetails[0]?.startLocation?.lat,
                    lng: routeDetails[0]?.startLocation?.lng,
                  }}
                  destinationCity={{
                    lat: routeDetails[0]?.endLocation?.lat,
                    lng: routeDetails[0]?.endLocation?.lng,
                  }}
                />
              </MapContainer>
            </Box>
            <Box>
              {routeDetails.map((route) => (
                <Box key={route.index}>
                  {/* <Typography
                    className="estimation"
                    sx={{ display: "inline-block" }}
                  >
                    <span>Estimated distance:</span> {route.distance}
                  </Typography>
                  <Typography
                    className="estimation"
                    sx={{ display: "inline-block", marginLeft: 10 }}
                  >
                    <span>Estimated time:</span> {route.duration}
                  </Typography> */}

                  <Box>
                    <Box
                      sx={{
                        padding: "45px 0 23px 0",
                        paddingLeft: "10%",
                        fontSize: "16px",
                        fontWeight: "700",
                        borderBottom: "1px solid #E6E6E6",
                      }}
                    >
                      Start from {sourceName}
                    </Box>
                    {route.steps.map((step) => (
                      <Typography className="listStep" key={step.step}>
                        <span className="listStepInstructrion">
                          {step.text}
                        </span>
                        <span className="direction-separator">
                          <span className="directions-line"></span>
                          <span className="directions-distance-time">
                            <span className="listStepDistance">
                              {step.distance}
                            </span>
                            <span className="listStepDuration">
                              {step.time}
                            </span>
                          </span>
                        </span>
                      </Typography>
                    ))}
                    <Box
                      sx={{
                        padding: "25px 0 45px 0",
                        paddingLeft: "10%",
                        fontSize: "16px",
                        fontWeight: "700",
                        borderBottom: "1px solid #E6E6E6",
                      }}
                    >
                      Location end at {destinatioinName}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrintableRoutes;
