import React, { useState, useEffect } from "react";
import "./tripPlanner.scss";
import "leaflet/dist/leaflet.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import citiesData from "../../lib/data.json";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
// import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#fff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#08652e",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#08652e",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#08652e",
    },
  },
}));

export default function TripPlanner({
  setSourceCity,
  setDestinationCity,
  isOpen,
  onClose,
}) {
  const classes = useStyles();

  const [selectSourcePlace, setSelectSourcePlace] = useState(null);
  const [selectDestinationPlace, setSelectDestinationPlace] = useState(null);

  const handleSourcePlace = async (sourcePlace) => {
    try {
      setSelectSourcePlace(sourcePlace); // Store the entire sourcePlace object

      const results = await geocodeByAddress(sourcePlace.label);
      const latLng = await getLatLng(results[0]);
      setSourceCity({ ...latLng, name: sourcePlace.label });
    } catch (err) {
      console.error("handleSourcePlace ~ err:", err);
    }
  };

  const handleDestinationPlace = async (destinationPlace) => {
    try {
      setSelectDestinationPlace(destinationPlace); // Store the entire destinationPlace object

      const results = await geocodeByAddress(destinationPlace.label);
      const latLng = await getLatLng(results[0]);
      setDestinationCity({ ...latLng, name: destinationPlace.label });
    } catch (err) {
      console.error("handleDestinationPlace ~ err:", err);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "&.MuiDialog-paper": { width: "100%", height: "300px !important" },
        }}
      >
        <div className="leaflet-container w-100">
          <div className="search-container">
            <div>
              <label className="locationLabel">Enter Source Location</label>
              <GooglePlacesAutocomplete
                apiKey="Your-API-Key"
                selectProps={{
                  value: selectSourcePlace, // Now storing the entire place object
                  onChange: handleSourcePlace, // Custom handler for selected place
                  placeholder: "Your Source Location",
                  className: "google-autocomplete",
                  getOptionLabel: (e) => e.label, // Explicitly defining how to get the label
                }}
              />
            </div>
            <div>
              <label className="locationLabel">
                Enter Destination Location
              </label>
              <GooglePlacesAutocomplete
                apiKey="Your-API-Key"
                selectProps={{
                  value: selectDestinationPlace, // Now storing the entire place object
                  onChange: handleDestinationPlace, // Custom handler for selected place
                  placeholder: "Your Destination Location",
                  className: "google-autocomplete",
                  getOptionLabel: (e) => e.label, // Explicitly defining how to get the label
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button variant="contained" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

