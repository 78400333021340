import {
  STATION_DETAILS,
  STATION_IMAGES,
  CONTACT_FORM,
} from "../constants/stationContants";
import apiRequest from "../../lib/apiRequest";
import {
  GET_ALL_STATIONS_DATA,
  GET_ALL_STATIONS_IMGS,
  POST_CONTACT_FORM,
} from "../../lib/apiConstants";

export const getStationsData = () => async (dispatch) => {
  try {
    const response = await apiRequest("GET", `${GET_ALL_STATIONS_DATA}`);
    dispatch({
      type: STATION_DETAILS,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.error("Group Types Error:", error);
    return;
  }
};

export const getStationsImagesData = () => async (dispatch) => {
  try {
    const response = await apiRequest("GET", `${GET_ALL_STATIONS_IMGS}`);
    dispatch({
      type: STATION_IMAGES,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.error("Group Types Error:", error);
    return;
  }
};

export const handleContactForm = (payload) => async (dispatch) => {
  try {
    const response = await apiRequest("POST", `${POST_CONTACT_FORM}`, payload);
    dispatch({
      type: CONTACT_FORM,
      payload: response?.data,
    });
    return response;
  } catch (error) {
    console.error("Group Types Error:", error);
    return;
  }
};
