import React, { useState } from "react";
import { Typography, Box, Button, Menu, MenuItem } from "@mui/material";
import Station from "../../assets/img/station.png";
import "./stationDetails.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import ContactModal from "./contactModal";

export default function StationDetails({
  station,
  isOpenModal,
  setOpenModal,
  onGetDirectionsClick,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContactModal, setOpenContactModal] = React.useState(false);
  const [IsEmail, setIsEmail] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenContactModal = (type) => {
    if (type === "email") {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
    setOpenContactModal(true);
    handleClose();
  };
  const handleCloseContactModal = () => setOpenContactModal(false);

  const handleCloseModal = () => setOpenModal(false);

 const handleDirectionsClick = () => {
   onGetDirectionsClick(station); // Send the station data)
   handleCloseModal(); // Optionally close the modal
 };

  // console.log(handleDirectionsClick, "handleDirectionsClick");
  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="station-details-section">
        <Box className="station-details-img">
          <img src={Station} alt="" />
        </Box>
        <Box className="station-details">
          <Typography variant="h2">{station?.StationName || "N/A"}</Typography>
          <p>
            {station?.StationAddress} {station?.StationCity}{" "}
            {station?.StationCountry}
          </p>
          <Typography variant="h3">
            Hours:
            <span style={{ marginLeft: "5px" }}>
              {station?.HoursOpen || "N/A"}
            </span>
          </Typography>
          <Typography variant="h3">
            Accepts:
            <span
              style={{
                wordBreak: "break-all",
                marginLeft: "5px",
                lineHeight: "26px",
              }}
            >
              {station?.PaymentTypesAccepted || "N/A"}
            </span>
          </Typography>
          <Box className="station-wrap">
            <Box className="station-status">
              <Typography variant="h4">Station Status</Typography>
              <Typography variant="h5">
                {station?.StationStatus || "N/A"}
              </Typography>
            </Box>
            <Box className="station-status">
              <Typography variant="h4">Vehicle Size</Typography>
              <Typography variant="h5">
                <span>
                  {station?.VehicleTypesBoxTrucks === "Yes"
                    ? "Vans and box trucks"
                    : ""}
                </span>
                <span>
                  {station?.VehicleTypesCarsAndVans === "Yes"
                    ? "Refuse and buses"
                    : ""}
                </span>
                <span>
                  {station?.VehicleTypesSemiTrucks === "Yes"
                    ? "Tractor-trailers"
                    : ""}
                </span>
              </Typography>
            </Box>
            <Box className="station-status">
              <Typography variant="h4">Fuel Type</Typography>
              <Typography variant="h5">
                {station?.StationFuelTypeCNG === "Yes" ? "CNG" : ""}
                {station?.StationFuelTypeDSL === "Yes" ? "DSL" : ""}
                {station?.StationFuelTypeLNG === "Yes" ? "LNG" : ""}
                {station?.StationFuelTypeRDM === "Yes" ? "RDM" : ""}
              </Typography>
            </Box>
          </Box>
          <Box className="btn-station">
            <Button onClick={handleDirectionsClick}>Get Directions</Button>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Share
            </Button>
            <Menu
              id="share-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="share-menu"
            >
              <MenuItem onClick={handleClose}>
                <FacebookIcon />
                Facebook
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <TwitterIcon />
                Twitter
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <GoogleIcon />
                Google+
              </MenuItem>
              <MenuItem onClick={() => handleOpenContactModal("email")}>
                <EmailIcon />
                E-mail Trip
              </MenuItem>
              <MenuItem onClick={() => handleOpenContactModal("info")}>
                <InfoIcon />
                Station Info
              </MenuItem>
            </Menu>
            <ContactModal
              openContactModal={openContactModal}
              handleCloseContactModal={handleCloseContactModal}
              station={station}
              IsEmail={IsEmail}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
