import React from 'react';
import './PromotionPopup.scss'; 

const PromotionPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="promotion-popup">
      <div className="promotion-popup-content">
        <h2>Special Promotion!</h2>
        <p>Check out our latest offers and discounts.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PromotionPopup;
