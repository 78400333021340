import React, { useState, useEffect } from 'react';
import './App.scss';
import { Box, Button } from '@mui/material';
import Header from './components/Header';
import MapComponent from './components/MapContainer';
import SearchOptions from './components/SearchOption';
import StationDetails from './components/StationDetails';
import { getStationsImagesData } from './store/actions/stationActions';
import { useDispatch } from 'react-redux';
import PromotionPopup from './components/PromotionPopup/index';
import CookieDisclaimer from './components/CookieDisclaimer';
// import TripPlanner from "./components/TripPlanner";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrintableRoutes from './components/PrintableRoutes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState({ lat: 38.8951, lng: -77.0364 });
  const [selectedStation, setSelectedStation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [position, setPosition] = useState(null);
  const [showPromotion, setShowPromotion] = useState(true);
  const [sourceCity, setSourceCity] = useState(null);
  const [destinationCity, setDestinationCity] = useState(null);
  const [routeDetails, setRouteDetails] = useState();
  // const [isTripPlannerOpen, setIsTripPlannerOpen] = useState(false);

  // const navigate = useNavigate();

  // const handleOpenTripPlanner = () => {
  //   setIsTripPlannerOpen(true);
  // };

  // const handleCloseTripPlanner = () => {
  //   setIsTripPlannerOpen(false);
  // };

  //  const handleGetDirectionsClick = () => {
  //    handleOpenTripPlanner();
  //  };

  const handleGetDirectionsClick = (station) => {
    setDestinationCity({
      lat: station?.Latitude,
      lng: station?.Longitude,
      name: station?.StationName,
    });

    handleShowLocation();
  };

  const handleLocationSelect = (location) => {
    console.log('handleLocationSelect ~ location:', location);
    setMapCenter(location);
    setPosition(location);
  };

  const handleStationSelect = (station) => {
    console.log('handleStationSelect ~ station:', station);
    setPosition(null);
    setSelectedStation(station);
  };

  const handleOpenModal = (station) => {
    setOpenModal(true);
  };

  const handleShowLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'denied') {
          toast.error('Please enable location services to use this feature.');
        } else {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { latitude, longitude } = pos.coords;
              const userLocation = { lat: latitude, lng: longitude };
              setSourceCity(userLocation);
              // setPosition(userLocation);
              setMapCenter(userLocation);
              toast.success('Location retrieved successfully!');
            },
            () => {
              toast.error('Unable to retrieve location.');
            }
          );
        }
      });
    } else {
      toast.error('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    dispatch(getStationsImagesData()).then((response) => {});
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Box className='App'>
              <ToastContainer />
              <Header
                onLocationSelect={handleLocationSelect}
                handleShowLocation={handleShowLocation}
                setSourceCity={setSourceCity}
                setDestinationCity={setDestinationCity}
                setRouteDetails={setRouteDetails}
              />
              <Box className='map-section'>
                <MapComponent
                  filterValues={filterValues}
                  center={mapCenter}
                  onStationSelect={handleStationSelect}
                  onOpenModal={handleOpenModal}
                  position={position}
                  handleShowLocation={handleShowLocation}
                  sourceCity={sourceCity}
                  destinationCity={destinationCity}
                  setRouteDetails={setRouteDetails}
                  routeDetails={routeDetails}
                  setSourceCity={setSourceCity}
                />
                {/* <PrintableRoutes  /> */}
                <SearchOptions
                  setFilterValues={setFilterValues}
                  routeDetails={routeDetails}
                />

                {/* <TripPlanner
                    setSourceCity={setSourceCity}
                    setDestinationCity={setDestinationCity}
                    isOpen={isTripPlannerOpen}
                    onClose={handleCloseTripPlanner}
                  /> */}
                <StationDetails
                  station={selectedStation}
                  isOpenModal={openModal}
                  setOpenModal={setOpenModal}
                  onGetDirectionsClick={handleGetDirectionsClick}
                />
              </Box>
              <PromotionPopup
                isOpen={showPromotion}
                onClose={() => setShowPromotion(false)}
              />
              <CookieDisclaimer />
            </Box>
          }
        />
        <Route
          path='/printable-routes'
          element={
            <PrintableRoutes
              routeDetails={routeDetails}
              sourceName={sourceCity?.name}
              destinatioinName={destinationCity?.name}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
