import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Search, Room, Map, Menu } from '@mui/icons-material';
import './header.scss';
import Logo from '../../assets/img/logo.svg';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import TripPlanner from '../TripPlanner';

const Header = ({
  onLocationSelect,
  handleShowLocation,
  setSourceCity,
  setDestinationCity,
  setRouteDetails,
}) => {
  const [selectedPlace, setSelectedPlace] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [tripPlannerOpen, setTripPlannerOpen] = useState(false);

  const handlePlaceSelect = async (place) => {
    console.log('place+++++', place);
    try {
      resetState();
      setRouteDetails(null);
      setSelectedPlace(place.label);
      setInputValue(place.label);
      const results = await geocodeByAddress(place.label);
      console.log('handlePlaceSelect ~ results:', results);
      const latLng = await getLatLng(results[0]);
      console.log('handlePlaceSelect ~ latLng:', latLng);
      onLocationSelect(latLng);
    } catch (error) {
      console.error('Error geocoding address:', error);
    }
  };

  const handleSearchClick = async () => {
    if (inputValue) {
      try {
        resetState();
        const results = await geocodeByAddress(inputValue);
        const latLng = await getLatLng(results[0]);
        onLocationSelect(latLng);
        setInputValue('');
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    } else {
      console.error('No location entered');
    }
  };

  const resetState = () => {
    setRouteDetails(null);
    setSourceCity(null);
    setDestinationCity(null);
    onLocationSelect(null);
  };

  const handleTripPlanner = async () => {
    try {
      resetState();
      setTripPlannerOpen(true);
    } catch (err) {
      console.error('handleTripPlanner ~ err:', err);
    }
  };

  return (
    <AppBar
      position='static'
      className='header'
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Toolbar className='toolbar'>
        <Box className='header-logo'>
          <img src={Logo} alt='' />
        </Box>
        <Typography variant='h6' className='renewable'>
          <span>Renewable</span> Natural Gas
        </Typography>
        <div className='grow' />
        <IconButton
          color='inherit'
          className='iconButton'
          onClick={handleShowLocation}
        >
          <Room />
          <Typography variant='body1' className='iconText'>
            Find a Nearby Station
          </Typography>
        </IconButton>
        <IconButton
          color='inherit'
          className='iconButton'
          onClick={handleTripPlanner}
        >
          {/* <NearMeIcon /> */}
          <Map />
          <Typography variant='body1' className='iconText'>
            Trip Planner
          </Typography>
        </IconButton>

        <div className='search'>
          <GooglePlacesAutocomplete
            apiKey='AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E' // Replace with your Google Places API Key
            selectProps={{
              value: selectedPlace,
              onChange: handlePlaceSelect, // Custom handler for selected place
              placeholder: 'Your destination',
            }}
          />
          <IconButton color='inherit' onClick={handleSearchClick}>
            <Search />
          </IconButton>
        </div>
        <IconButton color='inherit' className='iconButton'>
          <Map />
        </IconButton>
        <IconButton color='inherit' className='header-menu iconButton'>
          <Menu className='menu-icon' />
        </IconButton>
      </Toolbar>
      <TripPlanner
        isOpen={tripPlannerOpen}
        setDestinationCity={setDestinationCity}
        setSourceCity={setSourceCity}
        onClose={() => {
          setTripPlannerOpen(false);
        }}
      />
    </AppBar>
  );
};

export default Header;
