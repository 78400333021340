import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  FormGroup,
} from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import './searchOption.scss';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 26;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Comdata',
  'Fuelman Fleetwide',
  'Mastercard Fleet',
  'TCH',
  'Visa Fleet',
  'Amex',
  'Discover',
  'Tcheck',
  'WEX',
  'Clean Energy Fuel Card',
  'EFS',
  'Legacy EFS',
  'PFGift Card',
  'Voyager',
  'Fleetone',
  'PFGIFT Card',
  'Mastercard',
  'Speedway',
  'Visa',
];

const SearchOptions = ({ setFilterValues, routeDetails }) => {
  const navigate = useNavigate();
  const [isFormVisible, setFormVisible] = useState(false);
  const [paymentValue, setPaymentValue] = useState([]);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes('Select All')) {
      if (paymentValue.length === names.length) {
        setPaymentValue([]);
      } else {
        setPaymentValue(names);
      }
    } else {
      const newValues = value.includes('Select All')
        ? names
        : value.filter((item) => item !== 'Select All');

      setPaymentValue(newValues);
    }
  };

  const formik = useFormik({
    initialValues: {
      fuelType: [],
      vehicleSize: [],
      stationStatus: [],
      hours: [],
      paymentTypes: [],
    },
    onSubmit: (values) => {
      console.log('Form values:', { ...values, paymentTypes: paymentValue });
      setFilterValues({ ...values, paymentTypes: paymentValue });
    },
    onReset: () => {
      formik.resetForm();
      setPaymentValue([]);
    },
  });

  return (
    <Box>
      <Box className='search-option-main'>
        <Box className='search-option-header'>
          <Typography variant='h1' className='search-option-text'>
            Search Options
          </Typography>
          <IconButton onClick={toggleFormVisibility} sx={{ color: '#08652e' }}>
            {isFormVisible ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>

        {isFormVisible && (
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Fuel Type</FormLabel>
              <FormGroup>
                {['CNG', 'LNG'].map((type) => (
                  <FormControlLabel
                    key={type}
                    control={
                      <Checkbox
                        checked={formik.values.fuelType.includes(type)}
                        onChange={() => {
                          if (formik.values.fuelType.includes(type)) {
                            formik.setFieldValue(
                              'fuelType',
                              formik.values.fuelType.filter(
                                (item) => item !== type
                              )
                            );
                          } else {
                            formik.setFieldValue('fuelType', [
                              ...formik.values.fuelType,
                              type,
                            ]);
                          }
                        }}
                      />
                    }
                    label={type}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset' sx={{ mt: 2 }}>
              <FormLabel component='legend'>Vehicle Size</FormLabel>
              <FormGroup>
                {[
                  'Vans and box trucks',
                  'Refuse and buses',
                  'Tractor-trailers',
                ].map((size) => (
                  <FormControlLabel
                    key={size}
                    control={
                      <Checkbox
                        checked={formik.values.vehicleSize.includes(size)}
                        onChange={() => {
                          if (formik.values.vehicleSize.includes(size)) {
                            formik.setFieldValue(
                              'vehicleSize',
                              formik.values.vehicleSize.filter(
                                (item) => item !== size
                              )
                            );
                          } else {
                            formik.setFieldValue('vehicleSize', [
                              ...formik.values.vehicleSize,
                              size,
                            ]);
                          }
                        }}
                      />
                    }
                    label={size}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset' sx={{ mt: 2 }}>
              <FormLabel component='legend'>Station Status</FormLabel>
              <FormGroup>
                {['Operational', 'Under Maintenance', 'Coming Soon'].map(
                  (status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <Checkbox
                          checked={formik.values.stationStatus.includes(status)}
                          onChange={() => {
                            if (formik.values.stationStatus.includes(status)) {
                              formik.setFieldValue(
                                'stationStatus',
                                formik.values.stationStatus.filter(
                                  (item) => item !== status
                                )
                              );
                            } else {
                              formik.setFieldValue('stationStatus', [
                                ...formik.values.stationStatus,
                                status,
                              ]);
                            }
                          }}
                        />
                      }
                      label={status}
                    />
                  )
                )}
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset' sx={{ mt: 2 }}>
              <FormLabel component='legend'>Hours</FormLabel>
              <FormGroup>
                {['24/7', 'Open Now'].map((hour) => (
                  <FormControlLabel
                    key={hour}
                    control={
                      <Checkbox
                        checked={formik.values.hours.includes(hour)}
                        onChange={() => {
                          if (formik.values.hours.includes(hour)) {
                            formik.setFieldValue(
                              'hours',
                              formik.values.hours.filter(
                                (item) => item !== hour
                              )
                            );
                          } else {
                            formik.setFieldValue('hours', [
                              ...formik.values.hours,
                              hour,
                            ]);
                          }
                        }}
                      />
                    }
                    label={hour}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl component='fieldset' sx={{ mt: 2 }}>
              <FormLabel component='legend'>Payment Types</FormLabel>
              <p>Select all that apply from the list</p>
              <FormControl className='payment-select'>
                <InputLabel id='demo-multiple-checkbox-label'>Tag</InputLabel>
                <Select
                  labelId='demo-multiple-checkbox-label'
                  id='demo-multiple-checkbox'
                  multiple
                  value={
                    paymentValue.includes('Select All') ? names : paymentValue
                  }
                  onChange={handleChange}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={(selected) =>
                    selected.includes('Select All')
                      ? 'Select All'
                      : selected.join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value='Select All'>
                    <Checkbox checked={paymentValue.length === names.length} />
                    <ListItemText primary='Select All' />
                  </MenuItem>
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={paymentValue.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>

            <Box className='search-option-btn'>
              <Button variant='contained' color='primary' type='submit'>
                Apply
              </Button>
              <Button variant='outlined' color='secondary' type='reset'>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Box>
      {routeDetails && routeDetails.length > 0 && (
        <Box
          className='print-routes-main'
          style={{ top: '100px' }}
          onClick={function () {
            navigate('/printable-routes');
          }}
        >
          Print Routes
        </Box>
      )}
    </Box>
  );
};

export default SearchOptions;
