import React, { useState } from "react";
import "./CookieDisclaimer.scss";

const CookieDisclaimer = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    document.cookie = "cookieConsent=true; path=/";
  };

  if (accepted || document.cookie.includes("cookieConsent=true")) return null;

  return (
    <div className="cookie-disclaimer">
      <div className="cookie-disclaimer-content">
        <p>
          This website uses cookies to ensure you get the best experience on our
          website.
        </p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    </div>
  );
};

export default CookieDisclaimer;
