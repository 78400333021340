import { STATION_DETAILS, STATION_IMAGES } from "../constants/stationContants";

const initialState = {
  getStationsData: [],
  STATION_IMAGES: [],
};

const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATION_DETAILS:
      return {
        ...state,
        getStationsData: action.payload,
      };
    case STATION_IMAGES:
      return {
        ...state,
        getStationsImagesData: action.payload,
      };
    default:
      return state;
  }
};

export default groupReducer;
